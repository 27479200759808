<template>
  <div class='indexbox'>
    <div class="searchbox" v-hasPermi="['tol']">
      <div class="left">
        <el-input class="inp" size='small' v-model="keywords" placeholder="Type Text To Search" clearable
          style="width: 200px" @change='pagechage(1)'></el-input>
        <el-date-picker v-model="timearr" style="max-width:280px;margin-right:10px;" format='yyyy-MM-dd'
          value-format="yyyy-MM-dd" type="daterange" range-separator="-" start-placeholder="Creation Date Start"
          end-placeholder="End" clearable @change='pagechage(1)' />
        <el-select size='small' style="max-width:160px;margin-right:10px;" clearable v-model="assembly_status"
          placeholder="Status" @change='pagechage(1)'>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input class="inp" size='small' v-model="start_no" placeholder="Start No" clearable style="width: 200px"
          @change='pagechage(1)'></el-input>
        <el-input class="inp" size='small' v-model="end_no" placeholder="End No" clearable style="width: 200px"
          @change='pagechage(1)'></el-input>
      </div>
      <div class="right">
        <el-button class="download" type="primary" icon="el-icon-download"
          @click="$router.push('/transfer')">Import</el-button>
        <el-dropdown @command="handleCommand">
          <el-button type="primary">
            Export<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="Summary">Transfer Note Summary</el-dropdown-item>
            <el-dropdown-item command="Details">Transfer Note Details</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <el-button class="download" type="primary" icon="el-icon-download" @click="import_show = true">Import Void
          Orders</el-button> -->
      </div>
    </div>

    <div class="tablebox" v-hasPermi="['tol']">
      <div class="draggable" v-loading='loading'>
        <el-table :data="tableData" stripe @selection-change="SelectionChange" max-height='700px'>
          <!-- show-summary :summary-method="getSummaries" -->
          <el-table-column type="selection" fixed align='center' width='60' />
          <el-table-column prop="transfer_no" label="Transfer Order #" width='150' align='center' fixed />
          <el-table-column prop="creation_date" label="Creation Date" align='center' width='130' show-overflow-tooltip />
          <el-table-column prop="stock_sent_date" label="Stock Sent Date" width='160' align='center'
            show-overflow-tooltip />
          <el-table-column prop="stock_received_date" label="Stock Received Date" width='180' align='center'
            show-overflow-tooltip />
          <el-table-column prop="from" label="From" width='150' align='center' show-overflow-tooltip />
          <el-table-column prop="to" label="To" min-width='150' align='center' show-overflow-tooltip />
          <el-table-column prop="status" label="Status" min-width='100' align='center' show-overflow-tooltip />
          <el-table-column prop="users" label="Users" min-width='140' align='center' show-overflow-tooltip />
          <el-table-column prop="reference" label="Reference" width='160' align='center' show-overflow-tooltip />
        </el-table>
      </div>
      <el-pagination @current-change='pagechage' @size-change="handleSizeChange" :page-sizes="[10, 20, 50, 100]"
        background layout="total,sizes,prev, pager, next" v-show="total > 0" :current-page.sync="page"
        :page-size="page_size" :total="total" />
    </div>

    <!-- <el-dialog title="Import Void Orders" :visible.sync="import_show" width="35%" @close="dialogClose">
      <div class="btntop">
        <el-button @click="downloaddata" :loading='loadingb'>Download Excel Template</el-button>
      </div>
      <el-upload :limit='1' class="upload-demo" action='' drag :auto-upload="false" accept=".xlsx, .xls"
        :on-change="Uploadchange" :on-remove='clearFiles' :file-list="fileList">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Drag The File Here, Or<em>Click Upload</em></div>
        <div style="color:red" slot="tip">Tip: Only Import Is Allowed “xls” or “xlsx” Format File！</div>
      </el-upload>
      <div class="btnbox">
        <el-button v-hasPermi="['tol']" :disabled="!file" type="primary" :loading='loadinga' @click="importOrders">Import
          Void Orders</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getToken } from '@/utils/auth'
export default {
  data() {
    return {
      options: [
        {
          value: 'ORDERED',
          label: 'ORDERED'
        },
        {
          value: 'IN TRANSIT',
          label: 'IN TRANSIT'
        },
        {
          value: 'COMPLETED',
          label: 'COMPLETED'
        },
      ],
      timearr: [],
      assembly_status: '',
      keywords: '',
      start_no: '',
      end_no: '',
      total: 0,
      page: 1,
      page_size: 10,
      tableData: [],
      loading: false,
      idsarr: [],

      import_show: false,
      fileList: [],
      file: '',
      loadinga: false,
      loadingb: false
    };
  },
  computed: {
    ...mapGetters(['api_id']),
  },
  methods: {
    //列表
    getlist() {
      this.loading = true
      this.$post({
        url: "/app/transfer_order/pageList",
        data: {
          page: this.page,
          page_size: this.page_size,
          keywords: this.keywords,
          status: this.assembly_status,
          api_id: this.$store.state.user.api_id,
          start_time: this.timearr && this.timearr.length ? this.timearr[0] : '',
          end_time: this.timearr && this.timearr.length ? this.timearr[1] : '',
          start_no: this.start_no,
          end_no: this.end_no,
        },
        success: (res) => {
          this.page = res.data.current_page
          this.page_size = Number(res.data.per_page)
          this.tableData = res.data.data
          this.total = res.data.total
          this.Quantity = res.data.Quantity
          setTimeout(() => {
            this.loading = false
          }, 200);
        },
        tip: () => {
          this.loading = false
        },
      })
    },
    // 分页
    pagechage(page) {
      this.page = page
      this.tableData = []
      this.getlist()
    },
    // 分页
    handleSizeChange(size) {
      this.page_size = size
      this.tableData = []
      this.getlist()
    },
    // 多选
    SelectionChange(data) {
      this.idsarr = data
    },
    // 更多操作触发
    handleCommand(command) {
      switch (command) {
        case "Summary":
          this.exportSummary()
          break;
        case "Details":
          this.exportDetail()
          break;
        default:
          break;
      }
    },
    //调拨单主信息导出
    exportSummary() {
      if (this.loading) return;
      if (!this.idsarr.length) {
        this.$message({
          message: 'Please Select Transfer Order',
          type: 'warning'
        });
        return
      }
      this.loading = true
      let ids = this.idsarr.map(val => val.id).join()
      let url = `${this.httpPath}/app/transfer_order/exportSummary?token=${getToken()}&api_id=${this.$store.state.user.api_id}&ids=${ids}`
      window.open(url)
      setTimeout(() => {
        this.loading = false
      }, 500);
    },
    //调拨单详细信息导出
    exportDetail() {
      if (this.loading) return;
      if (!this.idsarr.length) {
        this.$message({
          message: 'Please Select Transfer Order',
          type: 'warning'
        });
        return
      }
      this.loading = true
      let ids = this.idsarr.map(val => val.id).join()
      let url = `${this.httpPath}/app/transfer_order/exportDetail?token=${getToken()}&api_id=${this.$store.state.user.api_id}&ids=${ids}`
      window.open(url)
      setTimeout(() => {
        this.loading = false
      }, 500);
    },


    //导入模板下载
    downloaddata() {
      this.loadingb = true
      this.$post({
        url: "/app/assembly_orders/getExcelUrl",
        success: (res) => {
          let url = res.data.url6
          window.open(url)
          setTimeout(() => {
            this.loadingb = false
          }, 1200);
        },
        tip: () => {
          setTimeout(() => {
            this.loadingb = false
          }, 1200);
        },
      })
    },
    // 导入 上传file
    Uploadchange(file) {
      this.file = file.raw
    },
    clearFiles() {
      this.fileList = []
      this.file = ''
    },
    dialogClose() {
      this.loadinga = false
      this.fileList = []
      this.file = ''
      this.import_show = false
    },
    importOrders() {
      this.loadinga = true
      let data = new FormData();
      data.append("file", this.file);
      data.append("api_id", this.$store.state.user.api_id);
      data.append("token", getToken());
      this.$post({
        url: "/app/assembly_orders/importVoidOrders",
        upload: true,
        data,
        success: (res) => {
          this.dialogClose()
          this.$message({
            message: 'Success',
            type: 'success',
            onClose: () => {
              this.pagechage(1)
            }
          });
        },
        tip: () => {
          this.dialogClose()
          setTimeout(() => {
            this.pagechage(1)
          }, 1500);
        },
      })
    },

    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Sum';
          return;
        }
        switch (column.property) {  // column.property可以匹配它的每一列的命名， 然后赋值
          case "Quantity":
            sums[index] = this.Quantity //值取自后台
            break;
          default:
            break;
        }
      });
      return sums;
    },
  },
  created() {
    this.getlist()
  },
}
</script>
<style lang='scss' scoped>
.indexbox {
  width: 100%;
  height: 100%;
  .searchbox {
    width: 100%;
    padding: 6px 15px;
    background: #fff;
    border-bottom: 1px solid #babdcd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .inp {
        margin-right: 10px;
      }
      .topboxs {
        position: relative;
        padding: 10px;
        .tit {
          position: absolute;
          top: 0px;
          left: 20px;
          padding: 2px 4px;
          background-color: #fff;
          z-index: 10;
          line-height: 1;
          font-size: 14px;
        }
      }
      /deep/.el-button {
        margin: 5px 10px;
      }
    }
    .download {
      background: #fff !important;
      color: #19cbbf !important;
    }
    /deep/.el-button {
      background-color: #d6f3e3;
      border-radius: 10px;
      color: #3b3b3b;
      border-color: #d6f3e3;
      align-self: flex-end;
      margin: 5px;
    }
  }
  .tablebox {
    width: 100%;
    padding: 20px;
  }
}
</style>
